import React, { useContext, useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import queryString from 'query-string';

import Context from 'components/common/Context'
import Layout from 'components/common/Layout'
import Axios from 'axios'


const scopesMap = {
    first_name: "First Name",
    middle_name: "Middle Name",
    last_name: "Last Name",
    issued_by: "Issued By",
    issued_date: "Issued Date",
    expiry_date: "Expiry Date",
    pass_photo: "Passport Photo",
    pass_data: "Passport Data",
    phone: "Phone",
    email: "Email",
    permanent_address: "Permanent Address",
    temporary_address: "Temporary Address",
    pinfl: "PINFL",
    inn: "INN",
    gender: "Gender",
    birth_date: "Birth Date",
    birth_place: "Birth Place",
    birth_country: "Birth_country",
    nationality: "Nationality",
    citizenship: "Citizenship",
    userdata: "Userdata"
}

const style = {
    label: {
        cursor: 'auto',
        marginBlock: '10px',
        marginInline: '20px',
    },
    scopes_cont: {
        overflowY: 'scroll',
        height: '300px',
        display: 'grid',
        textAlign: 'center',
    }
};



const getScopes = (clientData) => {
    return clientData.scopes.split(',').map((item, key) => {
        let _item = scopesMap[item.split(':')[0]];
        if (_item != null) {
            return (
                <span key={key} >
                    <i className="fa fa-check fa-lg" style={{ color: 'green' }} />
                    &nbsp; {scopesMap[item.split(':')[0]]}
                </span>
            )
        }
        return null
    })
}

const ClientAuthRequest = ({ clientData, user, onConfirm, onCancel }) => {

    if (window.localStorage.getItem(user.oauthData.client_id)) {
        onConfirm();
        return null;
    }

    return (
        <>
            <div>
                <div className="center-text">Please confirm</div>
                <h4>{clientData.app_name}</h4>
                <p><i>is asking to grant an access to your account info</i></p>
                <div style={style.scopes_cont}>
                    {getScopes(clientData)}
                </div>
            </div>

            <button className="btn my_btn" onClick={onConfirm}>Confirm</button>
            <button className="btn my_btn_cancel" onClick={onCancel}>Deny</button>
        </>
    )
}


export default (props) => {
    const { user } = useContext(Context);
    const [state, setState] = useState({ loading: true, client: null });

    const fetchClientInfo = async clientId => {
        const { data } = await Axios.get(`${process.env.API}/clients/${clientId}`)
        return data;
    }

    const onConfirm = async () => {
        setState({ loading: true })
        const { data } = await Axios.post(
            `${process.env.API}/oauth2/authorize-client?${queryString.stringify(user.oauthData)}`)

        window.localStorage.setItem(user.oauthData.client_id, true)
        window.location = data.Location;
    }

    const onCancel = async () => {
        const { oauthData } = user;
        const { data } = await Axios.post(
            `${process.env.API}/oauth2/deny-client?client_id=${oauthData.client_id}&redirect_uri=${oauthData.redirect_uri}`)
        console.log(data)
        window.location = data.Location;
    }

    useEffect(() => {
        // little check
        if (!user.oauthRequest) {
            return navigate('/');
        }

        setState({ loading: true })

        //fetch data if not confrimed previously
        fetchClientInfo(user.oauthData.client_id).then(data => {
            setState({ loading: false, client: data })
        }).catch(err => {
            setState({ loading: false })
        })
    }, [user])

    return (
        <Layout>
            <div className="auth_box auth_box_change">
                <div className="auth_tab">
                    {state.loading ? <div className="loader1">Loading...</div>
                        : <ClientAuthRequest clientData={state.client}
                            user={user}
                            onConfirm={onConfirm}
                            onCancel={onCancel}
                        />}
                </div>
            </div>
        </Layout>
    )
}
